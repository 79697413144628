import React, { useState, useReducer, useEffect } from "react";
export const AppContext = React.createContext(null);

function messgeBoxReducer(state, action) {
  switch (action.type) {
    case "CLOSE_MSG_BOX":
      return { open: false, header: "", message: "", color: "" };
    case "OPEN_MSG_BOX":
      	
      let color = '#1c8bc3';
      if(action.payload.header.toLowerCase() == "error")
        color = '#e53935';
      else if(action.payload.header.toLowerCase() == "success")
        color = '#388e3c';

      return {
        open: true,
        header: action.payload.header,
        message: action.payload.message,
        color: color,
      };
    default:
      return state;
  }
}
function confirmMessgeBoxReducer(state, action) {
  switch (action.type) {
    case "CLOSE_CONFIRM_MSG_BOX":
      return { open: false, header: "", message: "", color: "" };
    case "OPEN_CONFIRM_MSG_BOX":
      let color = "#3d6a7f";
      return {
        open: true,
        header: action.payload.header,
        message: action.payload.message,
        color: color,
      };
    case "CANCEL_CONFIRM_MSG_BOX":
      return { open: false, header: "", message: "", color: "", confirm: "" };
    case "OK_CONFIRM_MSG_BOX":
      return { open: false, header: "", message: "", color: "", confirm: "ok" };
    default:
      return state;
  }
}

export default ({ children }) => {
  // const [app_data, dispatch_app] = useReducer(appReducer, appInit);

  const initMgsState = { open: false, header: "", message: "", color: "" };
  const initMgsStateConfirm = {
    open: false,
    header: "",
    message: "",
    color: "",
    confirm: "ok",
  };

  const [error, dispatch_err] = useReducer(messgeBoxReducer, initMgsState);

  const [confirm_error, dispatch_confirm_err] = useReducer(
    confirmMessgeBoxReducer,
    initMgsStateConfirm
  );

  const [loader, setLoader] = useState(false);

  const [token, setToken] = useState("");

  const [fteachcode, setFteachcode] = useState("");
  const [fqpcode, setFqpcode] = useState("");
  const [isreviewer, setIsreviewer] = useState(false);
  const [fvalno, setFvalno] = useState("");
  const [univcode, setUnivcode] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem("rootPath") === "/srun") {
      setUnivcode("050");
    } else if (sessionStorage.getItem("rootPath") === "/gcg") {
      setUnivcode("033");
    } else if (sessionStorage.getItem("rootPath") === "/bcu") {
      setUnivcode("041");
    } else if (sessionStorage.getItem("rootPath") === "/rcub") {
      setUnivcode("027");
    } else if (sessionStorage.getItem("rootPath") === "/sdu") {
      setUnivcode("020");
    } else if (sessionStorage.getItem("rootPath") === "/demo") {
      setUnivcode("098");
    } else if (sessionStorage.getItem("rootPath") === "/acu") {
      setUnivcode("040");
    } else if (sessionStorage.getItem("rootPath") === "/reva") {
      setUnivcode("030");
    } else if (sessionStorage.getItem("rootPath") === "/dypatil") {
      setUnivcode("051");
    } else if (sessionStorage.getItem("rootPath") === "/msruas") {
      setUnivcode("053");
    }else if (sessionStorage.getItem("rootPath") === "/kus") {
      setUnivcode("003D");
    }else if (sessionStorage.getItem("rootPath") === "/vskub") {
      setUnivcode("026");
    }else if (sessionStorage.getItem("rootPath") === "/surana") {
      setUnivcode("060");
    }  
    else {
      setUnivcode(sessionStorage.getItem("univcode"));
    }
  }, []);

  const appStore = {
    err_msg: [error, dispatch_err],
    confirm_err_msg: [confirm_error, dispatch_confirm_err],
    load: [loader, setLoader],
    auth_token: [token, setToken],
    teachcode: [fteachcode, setFteachcode],
    qpcode: [fqpcode, setFqpcode],
    rev: [isreviewer, setIsreviewer],
    val: [fvalno, setFvalno],
    univcode: univcode,
  };
  return <AppContext.Provider value={appStore}>{children}</AppContext.Provider>;
};
